<script lang="ts">
  import { onDestroy } from 'svelte'
  import { Modal } from 'carbon-components-svelte'
  import { state } from '../../store/state'
  import { noop } from 'common/src/utils'

  export let danger = false
  export let warning = false
  export let open = false
  export let modalHeading = ''
  export let text = ''
  export let primaryText = ''
  export let secondaryText = ''
  export let slotComponent: any = undefined
  export let slotProps: any = {}
  export let primaryButtonDisabled = false

  let subscriptions: (() => void)[] = []

  let action: 'primary' | 'secondary' | 'close' = 'close'

  export let onSecondaryClick = () => {
    open = false
  }
  export let onPrimaryClick = () => {
    open = false
  }

  export let onClose = () => {
    if (action !== 'primary' && action !== 'secondary') {
      action = 'close'
      onSecondaryClick()
    }
  }

  export let onOpen = () => {
    action = 'close'
  }

  const modalUnsubscribe = state.modal.subscribe((m) => {
    subscriptions.forEach((s) => s())

    const confirm = m.primaryAction || { subscribe: noop }
    const cancel = m.secondaryAction || { subscribe: noop }

    subscriptions.push(
      m.heading.subscribe((value) => {
        modalHeading = value
      }),
      m.primaryButtonDisabled.subscribe((value) => {
        primaryButtonDisabled = value
      }),
      m.danger.subscribe((value) => {
        danger = value
      }),
      m.warning.subscribe((value) => {
        warning = value
      }),
      m.text.subscribe((value) => {
        text = value
      }),
      m.primaryText.subscribe((value) => {
        primaryText = value
      }),
      m.secondaryText.subscribe((value) => {
        secondaryText = value
      }),
      cancel.subscribe((value) => {
        const wrapped = () => {
          action = 'secondary'
          value()
        }
        onSecondaryClick = wrapped
      }),
      confirm.subscribe((value) => {
        const wrapped = () => {
          action = 'primary'
          value()
        }
        onPrimaryClick = wrapped
      })
    )

    slotComponent = m.slotComponent
    slotProps = m.slotProps
    open = m.open
  })

  onDestroy(() => {
    subscriptions.forEach((s) => s())

    modalUnsubscribe()
  })
</script>

<Modal
  bind:danger
  bind:open
  bind:modalHeading
  bind:primaryButtonText={primaryText}
  bind:secondaryButtonText={secondaryText}
  bind:primaryButtonDisabled
  on:click:button--secondary={onSecondaryClick}
  on:click:button--primary={onPrimaryClick}
  on:open={onOpen}
  on:close={onClose}
  class={warning ? 'warning-modal' : ''}
>
  <div class="root">
    <p>
      {text}
    </p>
    <div>
      {#if slotComponent}
        <svelte:component this={slotComponent} {...slotProps} />
      {/if}
    </div>
  </div>
</Modal>

<style>
  .root {
    padding: 1rem;
  }
  :global(.bx--modal-content),
  :global(.bx--modal-container) {
    overflow: visible;
  }

  :global(.bx--modal.warning-modal) :global(.bx--btn--primary) {
    background-color: var(--cds-warning);
  }

  :global(.bx--modal.warning-modal) :global(.bx--btn--primary):hover {
    background-color: var(--cds-hover-warning);
  }

  :global(.bx--modal.warning-modal) :global(.bx--btn--primary):active {
    background-color: var(--cds-active-warning);
  }
</style>
